import { useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import qs from "qs";

export const TournamentAttributes = {
    DURATIONS: "durations",
};

export const ComponentNames = {
    CARDS: "cards",
    TOURNAMENTS: "tournaments",
};

//todo: add more entries for other components
//todo: (related to top todo) add more attributes per component type as we get to more filtering
//todo: stronger caching like local storage for filters to persist when user leaves
//todo: maybe store this in a context
export const useFiltersByDashboardComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    //Cache filters so they stick around when user changes dashboards.
    //In a ref and not state so it syncs immediately with the query string wihtout having to run
    //an extra state update loop
    const filterCacheForNavLinks = useRef({ tournaments: {} });
    const attributeFilterValuesByDashboardComponent = {
        tournaments: {
            durations: ["DAILY", "WEEKLY", "QUARTERLY", "MONTHLY", "YEARLY"],
        },
    };
    const filtersApplied = qs.parse(location.search, {
        commas: true,
        ignoreQueryPrefix: true,
    });

    const handleFilterAddition = (attribute, value) => {
        if (!filtersApplied[attribute]?.includes(value)) {
            filtersApplied[attribute] = filtersApplied[attribute] || [];
            filtersApplied[attribute].push(value);
        }

        navigate({
            pathname: location.pathname,
            search: qs.stringify(filtersApplied, {
                arrayFormat: "brackets",
            }),
        });
    };

    const handleFilterRemoval = (attribute, value) => {
        filtersApplied[attribute] = filtersApplied[attribute].filter(
            (filterValue) => filterValue !== value,
        );

        navigate({
            pathname: location.pathname,
            search: qs.stringify(filtersApplied, {
                arrayFormat: "brackets",
            }),
        });
    };

    const getCachedFiltersByComponent = (componentName) => {
        return filterCacheForNavLinks.current[componentName];
    };

    const nameOfComponentSelected =
        location.pathname.replace("/app", "").split("/")[1] ||
        ComponentNames.CARDS;

    //update the filters with the new query string with updated filter params for the component currently being rendered
    filterCacheForNavLinks.current[nameOfComponentSelected] = filtersApplied;

    return {
        filtersApplied,
        attributeFilterValues:
            attributeFilterValuesByDashboardComponent[nameOfComponentSelected],
        handleFilterAddition,
        handleFilterRemoval,
        getCachedFiltersByComponent,
    };
};
