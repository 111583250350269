import { Breadcrumbs, Typography } from "@mui/joy";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Sheet from "@mui/joy/Sheet";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { findAllTournaments } from "../api/tournaments.service";
import TournamentDurationFilter from "./filterComponents/TournamentFilterComponents";
import {
    TournamentAttributes,
    useFiltersByDashboardComponent,
} from "../state/useFilters.state";

export default function TournamentsComponent() {
    const [tournaments, setTournaments] = useState([]);
    //todo-render a progress spinner when loading, will leave this for now
    const [loading, setLoading] = useState(true);
    //todo-render a more detailed error msg
    const [error, setError] = useState(false);
    //We'll temporarily keep this local to tournamentscomponent then move it globally for every component in a context
    //so we can maintain filters when switching or navigating between components
    const {
        filtersApplied,
        attributeFilterValues,
        handleFilterAddition,
        handleFilterRemoval,
    } = useFiltersByDashboardComponent();

    const filterTournaments = (tournaments) =>
        tournaments.filter((tournament) => {
            if (!filtersApplied[TournamentAttributes.DURATIONS]) {
                return true;
            }
            return filtersApplied[TournamentAttributes.DURATIONS].find(
                (duration) => duration === tournament.tournamentDuration,
            )
                ? true
                : false;
        });

    //todo-the actual links behind the names seem to be excessively longer than the names, fix later

    useEffect(() => {
        findAllTournaments()
            .then((res) => {
                if (res.error) {
                    setError(true);
                } else if (res.data) {
                    setTournaments(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            <Breadcrumbs
                separator={<KeyboardArrowLeft />}
                aria-label="breadcrumbs"
            >
                <Link to="/app">Dashboard</Link>
                <Typography>Tournaments</Typography>
            </Breadcrumbs>

            {loading === true ? (
                "loading"
            ) : error === true ? (
                "error"
            ) : (
                <>
                    <TournamentDurationFilter
                        durations={
                            attributeFilterValues[
                                TournamentAttributes.DURATIONS
                            ]
                        }
                        durationFiltersApplied={
                            filtersApplied[TournamentAttributes.DURATIONS] || []
                        }
                        handleDurationFilterSelection={(duration) =>
                            handleFilterAddition(
                                TournamentAttributes.DURATIONS,
                                duration,
                            )
                        }
                        handleDurationFilterRemoval={(duration) =>
                            handleFilterRemoval(
                                TournamentAttributes.DURATIONS,
                                duration,
                            )
                        }
                    />
                    {filterTournaments(tournaments).map((t) => {
                        return (
                            <Sheet key={t.id}>
                                <Typography
                                    component={Link}
                                    to={`/app/tournaments/${t.id}`}
                                >
                                    {t.tournamentName}
                                </Typography>
                            </Sheet>
                        );
                    })}
                </>
            )}
        </>
    );
}
