import { APIPaths } from "./paths";
import { urlBuilder } from "./paths";
import { withAuthTokenHeader } from "./utils";

//todo: add tournament id
export const getLeaderBoard = async (tournamentId) => {
    const res = {};
    try {
        const returned = await withAuthTokenHeader().get(
            urlBuilder.applyArgsToDynamicParams(
                APIPaths.LEADERBOARD,
                tournamentId,
            ),
        );
        res.data = returned.data;
    } catch (e) {
        res.error = e;
    }
    return res;
};
