import * as React from "react";
import { Breadcrumbs, Typography } from "@mui/joy";
import Button from "@mui/joy/Button";
import Snackbar from "@mui/joy/Snackbar";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
    getStockByTicker,
    getPricesForStockByTicker,
} from "../api/stocks.service";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function CardDetail() {
    const [loading, setLoading] = useState(true);
    const [stock, setStock] = useState({});
    const [stockHistoryData, setStockHistoryData] = useState([]);
    const [priceError, setPriceError] = useState(false);
    const [errorSnackOpen, setErrorSnackOpen] = useState(false);
    const { ticker } = useParams();
    const chartComponentRef = useRef(null);
    const curStockPriceData = useRef(null);

    useEffect(() => {
        const stock = getStockByTicker(ticker);
        const priceHistoryData = getPricesForStockByTicker(ticker);

        Promise.all([stock, priceHistoryData])
            .then((results) => {
                const [stock, priceHistoryData] = results;
                if (stock.error || priceHistoryData.error) {
                    setPriceError(true);
                    setErrorSnackOpen(true);
                } else {
                    // Store the latest price history
                    curStockPriceData.current = {
                        openPrice: priceHistoryData.data[0].openPrice,
                        closePrice: priceHistoryData.data[0].closePrice,
                    };
                    setStock(stock.data);
                    setStockHistoryData(priceHistoryData.data);
                }
            })
            .finally(() => setLoading(false));
    }, [ticker]);

    console.log(
        stockHistoryData.map((x) => {
            return [x.priceDate, x.closePrice];
        }),
    );

    const options = {
        title: {
            text: `${ticker} price history`,
        },
        xAxis: {
            type: "datetime",
        },
        series: [
            {
                type: "line",
                data: stockHistoryData.map((x) => {
                    return [Date.parse(x.priceDate), x.closePrice];
                }),
                dateFormat: "YYYY-MM-DD",
            },
        ],
    };

    return (
        <>
            {loading ? (
                "loading"
            ) : (
                <>
                    <Breadcrumbs
                        separator={<KeyboardArrowLeft />}
                        aria-label="breadcrumbs"
                    >
                        <Link to="/app">Dashboard</Link>
                        <Link to="/app/cards">Cards</Link>
                        <Typography>{ticker}</Typography>
                    </Breadcrumbs>
                    {priceError === false && (
                        <>
                            <Typography level="h2">
                                {stock.companyName}
                            </Typography>
                            <Typography level="h4">${stock.ticker}</Typography>

                            <Typography>
                                Price Open:{" "}
                                {curStockPriceData.current.openPrice}
                            </Typography>
                            <Typography>
                                Price Close:{" "}
                                {curStockPriceData.current.closePrice}
                            </Typography>
                            <Typography>-- Historical Data --</Typography>
                            {stockHistoryData.map((h, index) => (
                                <Typography key={index}>
                                    Price Close on {h.priceDate}: {h.closePrice}
                                </Typography>
                            ))}
                        </>
                    )}
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                        // {...props}
                    />
                    <Snackbar
                        variant="soft"
                        size="lg"
                        color="danger"
                        open={errorSnackOpen}
                        onClose={() => setErrorSnackOpen(false)}
                        endDecorator={
                            <Button
                                onClick={() => setErrorSnackOpen(false)}
                                size="sm"
                                variant="soft"
                                color="danger"
                            >
                                Dismiss
                            </Button>
                        }
                    >
                        Could not retrieve price data.
                    </Snackbar>
                </>
            )}
        </>
    );
}
