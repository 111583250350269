import React from "react";
import { URLBuilder } from "../utils/URLBuilder";
import { createBrowserRouter } from "react-router-dom";
import AwardsComponent from "../components/AwardsComponent";
import CardDetail from "../components/CardDetail";
import CardsComponent from "../components/CardsComponent";
import StatsComponent from "../components/StatsComponent";
import TournamentDetail from "../components/TournamentDetail";
import TournamentsComponent from "../components/TournamentsComponent";
import DashboardScreen from "../screens/DashboardScreen";
import LoginScreen from "../screens/LoginScreen";
import StockComponent from "../components/StockComponent";
import { LoginScreenGuardComponent } from "./components/LoginScreenGuardComponent";
import { AppGuardComponent } from "./components/AppGuardComponent";
import { TournamentRegistrationGuardComponent } from "./components/TournamentRegistrationGuardComponent";
import TournamentCardsEntryComponent from "../components/tournamentComponents/Registration/children/TournamentCardsEntryComponent";
import AccountScreen from "../components/AccountComponent";
import ExperienceScreen from "../components/ExperienceComponent";
import TournamentAccessComponent from "../components/tournamentComponents/PlayerVerification/TournamentAccessComponent";
import TournamentRegistrationComponent from "../components/tournamentComponents/Registration/TournamentRegistrationComponent";
import RegistrationLauncherComponent from "../components/tournamentComponents/Registration/children/RegistrationLauncherComponent";
import PlayerInTournamentComponent from "../components/tournamentComponents/PlayerInTournament/PlayerInTournamentComponent";

export const RootPaths = {
    MAIN_ROOT: "/",
    APP_ROOT: "/app",
};

export const UIRoutePaths = {
    ACCOUNT: `${RootPaths.APP_ROOT}/account`,
    AWARDS: `${RootPaths.APP_ROOT}/awards`,
    CARDS: `${RootPaths.APP_ROOT}/cards`,
    CARD_DETAILS: `${RootPaths.APP_ROOT}/cards/:ticker`,
    EXPERIENCE_HISTORY: `${RootPaths.APP_ROOT}/experience`,
    STOCKS: `${RootPaths.APP_ROOT}/stocks`,
    TOURNAMENTS: `${RootPaths.APP_ROOT}/tournaments`,
    TOURNAMENT_DETAILS: `${RootPaths.APP_ROOT}/tournaments/:id`,
    TOURNAMENT_REGISTRATION: `${RootPaths.APP_ROOT}/tournaments/:id/registration`,
    TOURNAMENT_CARDS_ENTRY: `${RootPaths.APP_ROOT}/tournaments/:id/registration/cards-entry/num-cards/:numCardsNeeded`,
    TOURNAMENT_GAME_SCREEN: `${RootPaths.APP_ROOT}/tournaments/:id/gameScreen`,
};

export const urlBuilderUI = URLBuilder.create(UIRoutePaths);

export const router = createBrowserRouter([
    {
        path: RootPaths.MAIN_ROOT,
        element: <LoginScreenGuardComponent />,
        children: [{ index: true, element: <LoginScreen /> }],
    },
    {
        path: RootPaths.APP_ROOT,
        element: <AppGuardComponent />,
        children: [
            {
                element: <DashboardScreen />,
                children: [
                    {
                        index: true,
                        element: <StatsComponent />,
                    },
                    {
                        path: UIRoutePaths.STOCKS,
                        element: <StockComponent />,
                    },
                    {
                        path: UIRoutePaths.AWARDS,
                        element: <AwardsComponent />,
                    },
                    {
                        path: UIRoutePaths.CARDS,
                        element: <CardsComponent />,
                        children: [
                            {
                                path: UIRoutePaths.CARD_DETAILS,
                                element: <CardDetail />,
                            },
                        ],
                    },
                    {
                        path: UIRoutePaths.TOURNAMENTS,
                        element: <TournamentsComponent />,
                    },
                    {
                        path: UIRoutePaths.TOURNAMENT_DETAILS,
                        element: <TournamentDetail />,
                        children: [
                            {
                                element: (
                                    <TournamentRegistrationGuardComponent />
                                ),
                                children: [
                                    {
                                        index: true,
                                        element: <TournamentAccessComponent />,
                                    },
                                    {
                                        path: UIRoutePaths.TOURNAMENT_REGISTRATION,
                                        element: (
                                            <TournamentRegistrationComponent />
                                        ),
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <RegistrationLauncherComponent />
                                                ),
                                            },
                                            {
                                                path: UIRoutePaths.TOURNAMENT_CARDS_ENTRY,
                                                element: (
                                                    <TournamentCardsEntryComponent />
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: UIRoutePaths.TOURNAMENT_GAME_SCREEN,
                                        element: (
                                            <PlayerInTournamentComponent />
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: UIRoutePaths.ACCOUNT,
                        element: <AccountScreen />,
                    },
                    {
                        path: UIRoutePaths.EXPERIENCE_HISTORY,
                        element: <ExperienceScreen />,
                    },
                ],
            },
        ],
    },
]);
