import { APIPaths } from "./paths";
import { withAuthTokenHeader } from "./utils";

//todo: add tournament id
export const getLeaderBoard = async () => {
    const res = {};
    try {
        const returned = await withAuthTokenHeader().get(APIPaths.LEADERBOARD);
        res.data = returned.data;
    } catch (e) {
        res.error = e;
    }
    return res;
};
