import { withAuthTokenHeader } from "./utils";
import { APIPaths } from "./paths";

export const getStockByTicker = async (ticker) => {
    const res = {};
    try {
        const returned = await withAuthTokenHeader().get(
            `${APIPaths.STOCKS}/${ticker}`,
        );
        res.data = returned.data;
    } catch (e) {
        res.error = e;
    }
    return res;
};

export const getStockMetricsByTicker = async (ticker) => {
    const res = {};

    try {
        const returned = await withAuthTokenHeader().get(
            `${APIPaths.STOCKS}/${ticker}/metrics2`,
        );
        res.data = returned.data;
    } catch (e) {
        res.error = e;
    }
    return res;
};

export const getPricesForStockByTicker = async (ticker) => {
    const res = {};
    try {
        const returned = await withAuthTokenHeader().get(
            `${APIPaths.STOCKS}/${ticker}/prices`,
        );
        res.data = returned.data;
    } catch (e) {
        res.error = e;
    }
    return res;
};

export const getPricesForStocksByTickers = async (tickers) => {
    const res = {};

    try {
        const returned = await withAuthTokenHeader().get(
            `${APIPaths.STOCKS_BY_TICKERS}?tickers=${tickers.join(",")}`,
        );
        res.data = returned.data;
    } catch (e) {
        res.error = e;
    }
    return res;
};
