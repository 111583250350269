import React from "react";
import { useState } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import { Box } from "@mui/joy";
import CssBaseline from "@mui/joy/CssBaseline";
import Layout from "./dashboard/Layout";
import Header from "./dashboard/Header";
import Navigation from "./dashboard/Navigation";
import { Outlet } from "react-router";
import HamburgerNav from "./responsive/HamburgerNav";

export default function DashboardScreen({ children }) {
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            {drawerOpen && (
                <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
                    <Navigation />
                </Layout.SideDrawer>
            )}
            {/*<Stack
                id="tab-bar"
                direction="row"
                spacing={1}
                sx={{
                    justifyContent: "space-around",
                    display: { xs: "flex", sm: "none" },
                    zIndex: "999",
                    bottom: 0,
                    position: "fixed",
                    width: "100dvw",
                    py: 2,
                    backgroundColor: "background.body",
                    borderTop: "1px solid",
                    borderColor: "divider",
                }}
            >
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/joy-ui/getting-started/templates/email/"
                    size="sm"
                    startDecorator={<EmailRoundedIcon />}
                    sx={{ flexDirection: "column", "--Button-gap": 0 }}
                >
                    Email
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    aria-pressed="true"
                    component="a"
                    href="/joy-ui/getting-started/templates/team/"
                    size="sm"
                    startDecorator={<PeopleAltRoundedIcon />}
                    sx={{ flexDirection: "column", "--Button-gap": 0 }}
                >
                    Team
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/joy-ui/getting-started/templates/files/"
                    size="sm"
                    startDecorator={<FolderRoundedIcon />}
                    sx={{ flexDirection: "column", "--Button-gap": 0 }}
                >
                    Files
                </Button>
            </Stack>*/}
            <Layout.Root
                sx={[
                    drawerOpen && {
                        height: "100vh",
                        overflow: "hidden",
                    },
                ]}
            >
                <Layout.Header>
                    <Box sx={{ display: { sm: "none" } }}>
                        <HamburgerNav
                            openMenu={() => setDrawerOpen(true)}
                            closeMenu={() => setDrawerOpen(false)}
                        />
                    </Box>
                    <Header />
                </Layout.Header>
                <Layout.SideNav>
                    <Navigation />
                </Layout.SideNav>
                <Layout.Main style={{ width: "100%" }}>
                    <Outlet />
                </Layout.Main>
            </Layout.Root>
        </CssVarsProvider>
    );
}
