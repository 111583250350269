import axios from "axios";
import { TOKEN_IN_LOCAL_STORAGE_LABEL } from "../globalConsts.constants";

export const withAuthTokenHeader = () => {
    if (!localStorage.getItem(TOKEN_IN_LOCAL_STORAGE_LABEL)) {
        throw new Error(`cannot find user token`);
    }

    return axios.create({
        headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN_IN_LOCAL_STORAGE_LABEL)}`,
        },
    });
};
