import { Typography } from "@mui/joy";
import { formatNumber } from "../../utils/numberFormatTools";

export default function NumberFormatter({ value, decimalPlaces, makePercent }) {
    let valueConverted = 0;

    try {
        valueConverted = formatNumber(
            value,
            decimalPlaces,
            makePercent || false,
        );
    } catch (e) {
        console.error("invalid value specified", value);
        valueConverted = "NaN";
    }

    return <>{valueConverted}</>;
}
