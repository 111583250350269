import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { router } from "./router/fantasy-stocks-router";
import { RouterProvider } from "react-router-dom";
import { AuthStateProvider } from "./state/auth.context";
import ConnectionStatusMessage from "./components/ConnectionStatusMessage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AuthStateProvider>
            <RouterProvider router={router} />
            {/* <App /> */}
            {/*<ConnectionStatusMessage />*/}
        </AuthStateProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
