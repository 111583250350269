import axios from "axios";
import { APIPaths } from "./paths";
import { withAuthTokenHeader } from "./utils";

//todo-wrap axios calls, maybe something like async get(url)=>{data? error?}, async post(url, payload)=>{data? error?}
export const findAllTournaments = async () => {
    let res = {};
    try {
        const fetched = await withAuthTokenHeader().get(APIPaths.TOURNAMENTS);
        res.data = fetched.data;
    } catch (e) {
        console.error(`error retrieving tournaments`, e);
        res.error = e;
    }

    return res;
};

export const tournamentById = async (id) => {
    const res = {};
    try {
        const fetched = await withAuthTokenHeader().get(
            `${APIPaths.TOURNAMENTS}/${id}`,
        );
        res.data = fetched.data;
    } catch (e) {
        console.error(`error retrieving tournaments`, e);
        res.error = e;
    }
    return res;
};

export const getUserRegistrationStateForTournament = async (id) => {
    const res = {};
    try {
        const fetched = await withAuthTokenHeader().get(
            `${APIPaths.TOURNAMENTS}/${id}/entries`,
        );
        res.data = fetched.data;
    } catch (e) {
        console.error(`error retrieving tournaments`, e);
        res.error = e;
    }
    return res;
};

export const registerCardsForTournament = async (id, cards) => {
    //we have to look at the status more closely here to discern
    //a user submission error (i.e. invalid set of cards) from
    //a server error
    let res = {};
    try {
        const fetched = await withAuthTokenHeader().post(
            `${APIPaths.TOURNAMENTS}/${id}/entry`,
            { tournamentId: id, cards },
        );
        res = { status: fetched.status, data: fetched.data };
    } catch (e) {
        console.error(`error registering tournament cards`, e);
        res = { status: e.status, error: e };
    }
    return res;
};
