import { createContext, useEffect, useMemo, useRef } from "react";
import { usePriceUpdateStore } from "./priceUpdates.store";
import { getPricesForStocksByTickers } from "../../api/stocks.service";

//interface PricesByTicker = {[ticker: string]: Price};
export const usePriceUpdatesService = (throttleTimeMS) => {
    const pricesByTicker = usePriceUpdateStore((state) => state.pricesByTicker);
    const updatingPrices = usePriceUpdateStore((state) => state.updatingPrices);
    const errorFetchingPrices = usePriceUpdateStore(
        (state) => state.errorFetchingPrices,
    );
    const setPricesByTicker = usePriceUpdateStore(
        (state) => state.setPricesByTicker,
    );
    const resetStore = usePriceUpdateStore((state) => state.resetStore);
    const setUpdatingPrices = usePriceUpdateStore(
        (state) => state.setUpdatingPrices,
    );
    const setErrorFetchingPrices = usePriceUpdateStore(
        (state) => state.setErrorFetchingPrices,
    );

    const throttleClock = useRef(null);

    const setThrottleClock = (throttleTimeMS) => {
        throttleClock.current = setTimeout(() => {
            setUpdatingPrices(true);
        }, throttleTimeMS);
    };

    console.log(JSON.stringify(usePriceUpdateStore.getState()));

    const usePricesFetchErrorStatus = () => {
        return usePriceUpdateStore((state) => state.errorFetchingPrices);
    };

    const usePriceFromStore = (ticker) => {
        let price = usePriceUpdateStore(
            (state) => state.pricesByTicker[ticker],
        );

        const priceFound = price || false;

        if (!priceFound) {
            price = { ticker, loadingInitial: true };
        }

        useEffect(() => {
            if (!priceFound) {
                loadPrice(ticker);
            }
        }, [priceFound]);

        return price;
    };

    const loadPrice = async (ticker) => {
        const res = await getPricesForStocksByTickers([ticker]);
        let price;

        if (res.error) {
            price = { ticker, error: "error fetching price" };
        } else {
            price = res.data[0];
        }
        setPricesByTicker({ [ticker]: price });
    };

    const updatePrices = async () => {
        const tickers = Object.keys(pricesByTicker);
        const hasLoadedPrices = tickers.length ? true : false;

        if (hasLoadedPrices) {
            const res = await getPricesForStocksByTickers(tickers);

            if (res.error) {
                setErrorFetchingPrices(true);
            } else {
                const updatedPrices = res.data.reduce(
                    (updatedPrices, price) => {
                        if (
                            JSON.stringify(pricesByTicker[price.ticker]) !==
                            JSON.stringify(price)
                        ) {
                            updatedPrices[price.ticker] = price;
                        } else {
                            updatedPrices[price.ticker] =
                                pricesByTicker[price.ticker];
                        }
                        return updatedPrices;
                    },
                    {},
                );

                setPricesByTicker(updatedPrices);
                setErrorFetchingPrices(false);
            }
        }

        setUpdatingPrices(false);
    };

    useEffect(() => {
        if (updatingPrices === true) {
            updatePrices();
        } else {
            setThrottleClock(throttleTimeMS);
        }
    }, [updatingPrices]);

    useEffect(() => {
        return () => {
            resetStore();
            clearTimeout(throttleClock.current);
        };
    }, []);

    return useMemo(
        () => ({
            subscribeToPriceFetchErrorStatus: usePricesFetchErrorStatus,
            subscribeToPriceUpdatesByTicker: usePriceFromStore,
        }),
        [],
    );
};

export const PriceService = createContext({
    priceService: null,
});

export const PriceServiceProvider = ({ children, timeThrottleMS }) => {
    const priceService = usePriceUpdatesService(timeThrottleMS);

    return (
        <PriceService.Provider value={{ priceService }}>
            {children}
        </PriceService.Provider>
    );
};
