import { useState, useEffect } from "react";
import * as React from "react";
import { Typography } from "@mui/joy";
import { getAllNews } from "../api/news.service";
import { Link } from "react-router-dom";

export default function StatsComponent() {
    const [loading, setLoading] = useState(true);
    const [news, setNews] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        getAllNews()
            .then((resp) => {
                if (resp.error) {
                    setError(true);
                } else {
                    setNews(resp.data);
                }
            })
            .finally(setLoading(false));
    }, []);
    return (
        <>
            <Typography>Top News:</Typography>
            {news.map((n) => (
                <Typography
                    component={Link}
                    to={n.storyUrl}
                    target="_blank"
                    key={n.id}
                >
                    {n.headline}
                </Typography>
            ))}
        </>
    );
}
