export const formatNumber = (value, numDecimals, includePercent = false) => {
    if (isNaN(value)) {
        throw new Error(
            `cannot format value: invalid value specified, ${value} is not a valid number`,
        );
    }

    let formatted;

    if (includePercent === true) {
        formatted = (value * 100).toFixed(numDecimals) + "%";
    } else {
        formatted = value.toFixed(numDecimals);
    }

    return formatted;
};
