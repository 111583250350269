import { Sheet, Typography } from "@mui/joy";
import { Link } from "react-router-dom";

export default function CardLink({ stock }) {
    return (
        <>
            <Sheet
                component={Link}
                style={styles.cardStyles}
                key={stock.ticker}
                to={stock.ticker}
            >
                <Typography level="h3" sx={{ color: "#fff" }}>
                    {stock.ticker}
                </Typography>
            </Sheet>
        </>
    );
}

const styles = {
    cardStyles: {
        textDecoration: "none",
        // border: "4px solid #0cf",
        backgroundColor: "#0cf",
        display: "inline-block",
        width: "200px",
        height: "280px",
        marginRight: "20px",
        marginBottom: "20px",
        padding: "10px",
        borderRadius: "6px",
    },
};
