import { useLocation } from "react-router";
import SettingsScreen from "./SettingsScreen";
import AppearanceSettingsComponent from "./components/AppearanceSettingsComponent";

export const RootSettingsPath = "#settings";

export const SettingsPaths = {
    APPEARANCE_SETTINGS: `${RootSettingsPath}/appearance`,
};

export default function SettingsRouter() {
    const location = useLocation();

    return (
        <SettingsScreen>
            {(() => {
                switch (location.hash) {
                    case RootSettingsPath:
                        return <AppearanceSettingsComponent />;
                    case SettingsPaths.APPEARANCE_SETTINGS:
                        return <AppearanceSettingsComponent />;
                    default:
                        return <></>;
                }
            })()}
        </SettingsScreen>
    );
}
