import { Box, Breadcrumbs, Typography } from "@mui/joy";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useContext, useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { getAllUserCards } from "../api/cards.service";
import {
    PriceService,
    PriceServiceProvider,
} from "../store/priceUpdates/priceUpdatesService.state";
import CardLink from "./CardLink";

export default function CardsComponent() {
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { ticker } = useParams();

    useEffect(() => {
        if (!ticker && !cards.length) {
            setLoading(true);
            getAllUserCards()
                .then((res) => {
                    if (res.error) {
                        setError(true);
                    } else {
                        setCards(res.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [ticker]);

    return (
        <PriceServiceProvider timeThrottleMS={10000}>
            <>
                <PricesFetchErrorMessage />
                {!ticker ? (
                    <AllCards loading={loading} error={error} cards={cards} />
                ) : (
                    <Outlet />
                )}
            </>
        </PriceServiceProvider>
    );
}

function AllCards({ loading, error, cards }) {
    return (
        <>
            <Breadcrumbs
                separator={<KeyboardArrowLeft />}
                aria-label="breadcrumbs"
            >
                <Link to="/app">Dashboard</Link>
                <Typography>Cards</Typography>
            </Breadcrumbs>
            {loading === true
                ? "loading"
                : error === true
                  ? "error"
                  : cards.map((c) => {
                        return <CardLink key={c.ticker} stock={c} />;
                    })}
        </>
    );
}

function PricesFetchErrorMessage() {
    const { priceService } = useContext(PriceService);
    const errorFetching = priceService.subscribeToPriceFetchErrorStatus();

    return (
        <Box
            sx={{
                display: errorFetching === true ? "block" : "none",
                textAlign: "center",
            }}
        >
            <Typography>error fetching price updates</Typography>
        </Box>
    );
}
