import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Autocomplete from "@mui/joy/Autocomplete";

//todo: paint the filter tags in when the user navigates here with filters applied (in the query string as is the current
//implementation) but not from clicking on a filter i.e. if the user navigates away to another page but comes back
//with the filters still saved, we must ensure the tags are still added
export default function TournamentDurationFilter({
    durations,
    durationFiltersApplied,
    handleDurationFilterSelection,
    handleDurationFilterRemoval,
}) {
    const handleSelectionChange = (updatedListOfSelectedDurations) => {
        if (
            updatedListOfSelectedDurations.length >
            durationFiltersApplied.length
        ) {
            handleDurationFilterSelection(
                [...updatedListOfSelectedDurations].pop(),
            );
        } else {
            //find the duration not in the new list of selected durations after user deleted its tag
            if (!updatedListOfSelectedDurations) {
                //we'll assume there was only one filter applied before it's tag was deleted, emptying the array
                handleDurationFilterRemoval(durationFiltersApplied[0]);
            } else {
                handleDurationFilterRemoval(
                    durationFiltersApplied.find(
                        (durationFilter) =>
                            !updatedListOfSelectedDurations.includes(
                                durationFilter,
                            ),
                    ),
                );
            }
        }
    };
    return (
        <FormControl size="sm">
            <FormLabel>by duration</FormLabel>
            <Autocomplete
                multiple
                placeholder="durations"
                options={durations}
                getOptionLabel={(option) => option}
                onChange={(e, durationsSelected) => {
                    handleSelectionChange(durationsSelected);
                }}
            />
            <FormHelperText>tournament durations</FormHelperText>
        </FormControl>
    );
}
