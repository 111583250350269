import { generatePath, useOutletContext } from "react-router";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/joy";
import { UIRoutePaths } from "../../../../router/fantasy-stocks-router";

export default function RegistrationLauncherComponent() {
    const { tournament } = useOutletContext();
    const cardEntryURL = generatePath(UIRoutePaths.TOURNAMENT_CARDS_ENTRY, {
        id: tournament.id,
        numCardsNeeded: tournament.numberOfCards,
    });

    return (
        <Box>
            <Typography>You are not playing</Typography>
            <Button
                variant="plain"
                color="neutral"
                component="a"
                size="sm"
                sx={{
                    flexDirection: "column",
                    "--Button-gap": 0,
                    backgroundColor: "red",
                }}
            >
                <Link to={cardEntryURL} replace={true}>
                    Enter Tournament
                </Link>
            </Button>
        </Box>
    );
}
