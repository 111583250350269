import { createContext, useEffect, useState } from "react";
import { getSettings } from "../api/settings.service";

export const SettingsTypes = {
    APPEARANCE: "appearance",
};

//interface AppearanceState is a CSS object i.e. {display: "flex"}
export const useAppearanceState = () => {
    const [appearanceState, setAppearanceState] = useState({
        backgroundColor: "background.appBody",
    });

    const AppearanceTypes = {
        PROPS: { BACKGROUND_COLOR: "backgroundColor" },
        COLORS: {
            WHITE: "#ffffff",
            BLACK: "#000000",
            APPBODY: "background.appBody",
        },
    };

    //input param is a partial of AppearanceState
    const updateAppearance = (input) => {
        setAppearanceState((state) => ({ ...state, ...input }));
    };

    return { appearanceState, AppearanceTypes, updateAppearance };
};

export const useSettingsService = () => {
    const appearanceSettings = useAppearanceState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const subscribeToSetting = (settingType) => {
        switch (settingType) {
            case SettingsTypes.APPEARANCE:
                return appearanceSettings;
            default:
                return null;
        }
    };

    useEffect(() => {
        getSettings()
            .then((res) => {
                if (res.data) {
                    appearanceSettings.updateAppearance(res.data.APPEARANCE);
                } else if (res.error) {
                    setError(true);
                }
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        loadingSettings: loading,
        errorLoadingSettings: error,
        subscribeToSetting,
    };
};

export const SettingsService = createContext();

export const SettingsProvider = ({ children }) => {
    const settingsService = useSettingsService();

    return (
        <SettingsService.Provider value={settingsService}>
            {children}
        </SettingsService.Provider>
    );
};
