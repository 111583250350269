import { useContext } from "react";
import { Breadcrumbs, Typography } from "@mui/joy";
import Button from "@mui/joy/Button";
import Snackbar from "@mui/joy/Snackbar";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useAPI } from "../state/api.state";
import {
    getStockByTicker,
    getPricesForStockByTicker,
} from "../api/stocks.service";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PriceComponent from "./priceComponents/PriceComponent";
import { PriceService } from "../store/priceUpdates/priceUpdatesService.state";

export default function CardDetail() {
    const { ticker } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);

    return (
        <>
            <Breadcrumbs
                separator={<KeyboardArrowLeft />}
                aria-label="breadcrumbs"
            >
                <Link to="/app">Dashboard</Link>
                <Link to="/app/cards">Cards</Link>
                <Typography>{ticker}</Typography>
            </Breadcrumbs>
            <CurrentStockData
                ticker={ticker}
                setErrorMessage={setErrorMessage}
            />
            <StockHistory ticker={ticker} setErrorMessage={setErrorMessage} />
            <Snackbar
                variant="soft"
                size="lg"
                color="danger"
                open={errorMessage ? true : false}
                onClose={() => setErrorMessage(null)}
                endDecorator={
                    <Button
                        onClick={() => setErrorMessage(null)}
                        size="sm"
                        variant="soft"
                        color="danger"
                    >
                        Dismiss
                    </Button>
                }
            >
                {errorMessage}
            </Snackbar>
        </>
    );
}

function CurrentStockData({ ticker, setErrorMessage }) {
    const { priceService } = useContext(PriceService);
    const priceState = priceService.subscribeToPriceUpdatesByTicker(ticker);
    const {
        stockData: stock = null,
        priceChange = null,
        openPrice = null,
        previousClosePrice = null,
        closePrice = null,
    } = priceState;

    useEffect(() => {
        if (priceState.error) {
            setErrorMessage(priceState.error);
        }
    }, [priceState.error]);

    return (
        <>
            <img alt={`${ticker} Logo`} src={stock?.logoUrl} height="50px" />
            {priceState.loadingInitial && (
                <Typography>fetching initial price</Typography>
            )}
            {!priceState.loadingInitial && !priceState.error && (
                <>
                    <Typography level="h2">{stock.companyName}</Typography>
                    <Typography level="h4">${ticker}</Typography>

                    <Typography>Price Open: {openPrice}</Typography>
                    <Typography>Price Close: {closePrice}</Typography>
                    {/*<PriceComponent ticker={stock.ticker} />*/}
                </>
            )}
        </>
    );
}

function StockHistory({ ticker, setErrorMessage }) {
    const {
        loading,
        status,
        data: stockHistory = [],
        error,
    } = useAPI(getPricesForStockByTicker(ticker));

    const chartComponentRef = useRef(null);

    useEffect(() => {
        if (error) {
            setErrorMessage("error retrieving stock history");
        }
    }, [error]);

    console.log(
        stockHistory?.map((x) => {
            return [x.priceDate, x.closePrice];
        }),
    );

    //enclosed in a function so we only access history when we have history data
    const getOptions = (stockHistory) => ({
        title: {
            text: `${ticker} price history`,
        },
        xAxis: {
            type: "datetime",
        },
        series: [
            {
                type: "line",
                data: stockHistory.map((x) => {
                    return [Date.parse(x.priceDate), x.closePrice];
                }),
                dateFormat: "YYYY-MM-DD",
            },
        ],
    });

    return (
        <>
            {loading && <Typography>loading history</Typography>}
            {!loading && !error && (
                <>
                    <Typography>-- Historical Data --</Typography>
                    {stockHistory.map((h, index) => (
                        <Typography key={index}>
                            Price Close on {h.priceDate}: {h.closePrice}
                        </Typography>
                    ))}
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={getOptions(stockHistory)}
                        ref={chartComponentRef}
                        // {...props}
                    />
                </>
            )}
        </>
    );
}
