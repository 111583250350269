import { APIPaths } from "./paths";
import { apiContext } from ".";
import { CONTEXT_OPTIONS } from "./helpers/APIContext";
import { SettingsTypes } from "../state/settings.context";
import AppearanceSettingsComponent from "../screens/Settings/components/AppearanceSettingsComponent";

//FOR NOW: returns Promise<{"backgroundColor": "#ffffff",}>
export const getSettings = async () => {
    /*const res = await apiContext.get(APIPaths.SETTINGS, {
        [CONTEXT_OPTIONS.NEEDS_AUTH]: true,
    });
    if (!res.error) {
        return { [SettingsTypes.APPEARANCE]: res.data };
    }
    return res;*/
    return {
        data: {
            [SettingsTypes.APPEARANCE]: {
                backgroundColor: "background.appBody",
            },
        },
    };
};
