import React, { ReactElement, useContext } from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { RootPaths, UIRoutePaths } from "../fantasy-stocks-router";
import { AuthState } from "../../state/auth.context";

export const LoginScreenGuardComponent = ({ children }) => {
    const { sessionState } = useContext(AuthState);
    const isLoggedIn = sessionState.token ? true : false;
    //kick the user to the main app content if
    //a) user is already in the login page and logged in successfully
    //b) user tried to come here for some reason while logged in
    return isLoggedIn === true ? (
        <Navigate to={RootPaths.APP_ROOT} />
    ) : (
        <Outlet />
    );
};
