import { Breadcrumbs, Sheet, Typography } from "@mui/joy";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import { getAllUserCards } from "../api/cards.service";
import { useAPI } from "../state/api.state";
import CardLink from "./CardLink";

export default function CardsComponent() {
    const { loading, data: cards, error } = useAPI(getAllUserCards());

    return (
        <>
            <Breadcrumbs
                separator={<KeyboardArrowLeft />}
                aria-label="breadcrumbs"
            >
                <Link to="/app">Dashboard</Link>
                <Typography>Cards</Typography>
            </Breadcrumbs>
            {loading === true
                ? "loading"
                : error === true
                  ? "error"
                  : cards.map((c) => {
                        return <CardLink stock={c} />;
                    })}
        </>
    );
}
