import { Box } from "@mui/joy";
import { usePlayerInTournament } from "../../../state/usePlayerInTournament.state";
import CardDeck from "./children/CardDeckComponent";
import LeaderBoard from "./children/LeaderBoardComponent";
import ThrottleClock from "./children/ThrottleClockComponent";
import { useOutletContext } from "react-router";

//todo: maybe rename this component
export default function PlayerInTournamentComponent() {
    const { cardEntries } = useOutletContext();
    const { throttling, cardDeckState, leaderBoardState } =
        usePlayerInTournament(
            cardEntries.map((card) => card.ticker),
            5000,
        );

    return (
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: "2em" }}>
            {throttling && <ThrottleClock throttleTimeInMS={5000} />}
            <LeaderBoard leaderBoardState={leaderBoardState} />
            <CardDeck cardDeckState={cardDeckState} />
        </Box>
    );
}
