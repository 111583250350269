import React, { ReactElement, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/joy";
import { RootPaths } from "../fantasy-stocks-router";
import { AuthState } from "../../state/auth.context";
import { AccountProvider } from "../../state/account.context";
import { SettingsProvider } from "../../state/settings.context";
import SettingsRouter, {
    RootSettingsPath,
} from "../../screens/Settings/setings-router";

export const AppGuardComponent = ({ children }) => {
    const { sessionState } = useContext(AuthState);
    const isLoggedIn = sessionState.token ? true : false;

    //kick the user out of the main app after logout/if not logged in
    return isLoggedIn === false ? (
        <Navigate to={RootPaths.MAIN_ROOT} />
    ) : (
        <AccountProvider>
            <SettingsProvider>
                <MainContent />
            </SettingsProvider>
        </AccountProvider>
    );
};

function MainContent() {
    //If the user wants to go into settings, we have to hide the
    //main dashboard but still render whatever screen we are at
    //in the main dashboard (i.e. what Outlet resolves to) so it's
    //ready when the user exits settings
    const location = useLocation();
    const userInSettings = location.hash
        ? location.hash.startsWith(RootSettingsPath)
        : false;
    const settingsContentDisplay = userInSettings ? "block" : "none";
    const normalContentDisplay = !userInSettings ? "block" : "none";

    return (
        <>
            <Box sx={{ display: settingsContentDisplay }}>
                <SettingsRouter />
            </Box>
            <Box sx={{ display: normalContentDisplay }}>
                <Outlet />
            </Box>
        </>
    );
}
