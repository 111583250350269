import React, { ReactElement, useContext } from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { RootPaths, UIRoutePaths } from "../fantasy-stocks-router";
import { AuthState } from "../../state/auth.context";
import { AccountProvider } from "../../state/account.context";

export const AppGuardComponent = ({ children }) => {
    const { sessionState } = useContext(AuthState);
    const isLoggedIn = sessionState.token ? true : false;

    //kick the user out of the main app after logout/if not logged in
    return isLoggedIn === false ? (
        <Navigate to={RootPaths.MAIN_ROOT} />
    ) : (
        <AccountProvider>
            <Outlet />
        </AccountProvider>
    );
};
