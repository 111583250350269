import { Box, Grid, Typography } from "@mui/joy";
import NumberFormatter from "../../../utilComponents/NumberFormatter";

export default function LeaderBoard({ leaderBoardState }) {
    /*
{
        "rank": 1,
        "portfolio_return": .05,
        "total_players": 999,
        "results": [
            { "rank": 1, "username": "me", "portfolio_return": .05, "is_me": True},
            { "rank": 1, "username": "not me 1", "portfolio_return": .04, "is_me": False},
            { "rank": 1, "username": "not me 2", "portfolio_return": .03, "is_me": False},
            { "rank": 1, "username": "not me 3", "portfolio_return": .02, "is_me": False},
        ]
    }
    */
    const { leaderBoard, leaderBoardUpdating, error } = leaderBoardState;

    return (
        <Box>
            <h4 style={{ margin: 0 }}>
                <b>Leaderboard</b>
            </h4>
            {leaderBoardUpdating && (
                <Typography>updating leader board...</Typography>
            )}
            {error && <Typography>error updating leaderboard...</Typography>}
            {leaderBoard && <LeaderBoardGrid leaderBoardData={leaderBoard} />}
        </Box>
    );
}

function LeaderBoardGrid({ leaderBoardData }) {
    const col1Size = 4;
    const col2Size = 4;
    const col3Size = 4;

    return (
        <Box>
            <Typography>
                <b>{`You are ranked ${leaderBoardData.rank} of ${leaderBoardData.totalPlayers} players.`}</b>
            </Typography>
            <Grid
                container
                sx={{
                    "& .MuiBox-root": {
                        boxSizing: "border-box",
                        padding: "3px",
                        borderRight: "1px solid",
                        borderBottom: "1px solid",
                    },
                    borderTop: "1px solid",
                    borderLeft: "1px solid",
                }}
            >
                <Grid xs={col1Size}>
                    <Box>Rank</Box>
                </Grid>
                <Grid xs={col2Size}>
                    <Box>Username</Box>
                </Grid>
                <Grid xs={col3Size}>
                    <Box>Return</Box>
                </Grid>
                {leaderBoardData.results.map((result) => (
                    <>
                        <Grid
                            xs={col1Size}
                            sx={{
                                backgroundColor: result.isMe
                                    ? "yellow"
                                    : "white",
                            }}
                        >
                            <Box>{result.rank}</Box>
                        </Grid>
                        <Grid
                            xs={col2Size}
                            sx={{
                                backgroundColor: result.isMe
                                    ? "yellow"
                                    : "white",
                            }}
                        >
                            <Box>{result.username}</Box>
                        </Grid>
                        <Grid
                            xs={col3Size}
                            sx={{
                                backgroundColor: result.isMe
                                    ? "yellow"
                                    : "white",
                            }}
                        >
                            <Box>
                                <NumberFormatter
                                    value={result.portfolioReturn}
                                    decimalPlaces={2}
                                    makePercent={true}
                                />
                            </Box>
                        </Grid>
                    </>
                ))}
            </Grid>
        </Box>
    );
}
