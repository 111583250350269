import * as React from "react";
import Box from "@mui/joy/Box";
import { Autocomplete } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Avatar from "@mui/joy/Avatar";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import ListDivider from "@mui/joy/ListDivider";
import { useNavigate } from "react-router-dom";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Logo from "../../images/FINIMMERSE-A.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { useContext, useEffect } from "react";
import { AuthState } from "../../state/auth.context";
import { APIPaths } from "../../api/paths";
import { AccountState } from "../../state/account.context";
import DefaultAvatar from "../../images/avatardefault_92824.png";
import { UIRoutePaths } from "../../router/fantasy-stocks-router";

export default function Header() {
    const [searchData, setSearchData] = React.useState([]);
    const navigate = useNavigate();

    function handleSearchInput(e) {
        axios
            .get(APIPaths.SEARCH)
            .then((resp) => {
                setSearchData(resp.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
            }}
        >
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: { xs: "none", sm: "flex" },
                }}
            >
                <IconButton
                    component={Link}
                    to="/app"
                    size="md"
                    variant="outlined"
                    color="neutral"
                    sx={{
                        display: { xs: "none", sm: "inline-flex" },
                        borderRadius: "50%",
                    }}
                >
                    <img src={Logo} height={20} alt="Finimmerse Logo" />
                </IconButton>
                <Typography>Fantasy Stocks</Typography>
            </Stack>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1.5,
                    alignItems: "center",
                }}
            >
                <Autocomplete
                    placeholder="Type anything"
                    options={searchData.map((opt) => ({
                        label: `${opt.type}: ${opt.label}`,
                        value: `/app/cards/${opt.value}`,
                    }))}
                    onInputChange={(event, value) => handleSearchInput(value)}
                    onBlur={() => setSearchData([])}
                    onChange={(e, value) => {
                        if (value?.value) navigate(value?.value);
                    }}
                    sx={{
                        width: "400px",
                    }}
                />
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={{
                        display: { xs: "inline-flex", sm: "none" },
                        alignSelf: "center",
                    }}
                >
                    <SearchRoundedIcon />
                </IconButton>
                <UserMenu />
            </Box>
        </Box>
    );
}

//todo: maybe move other header items like search tool into their own components for consistency
//and in case they get more complex

//todo: replace the default avatar with one chosen by the user

//todo: improve the UI for loading account workflow, make it centralized so we don't have
//multiple widgets reporting an error state for account loading

function UserMenu() {
    const { sessionState, useLogout } = useContext(AuthState);
    const { basicAccountInfoState } = useContext(AccountState);

    const { submittingLogout, logoutSucceeds, logoutFails, beginLogout } =
        useLogout();

    const {
        basicAccountInfo,
        loadingBasicAccountInfo,
        errorRetrievingBasicAccountInfo,
    } = basicAccountInfoState;

    useEffect(() => {
        if (logoutSucceeds === true) {
            sessionState.clearSessionAfterLogout();
        }
    }, [logoutSucceeds]);

    return (
        <>
            {" "}
            <Dropdown>
                <MenuButton
                    variant="plain"
                    size="sm"
                    sx={{
                        maxWidth: "32px",
                        maxHeight: "32px",
                        borderRadius: "9999999px",
                    }}
                >
                    <img src={DefaultAvatar} height={20} alt="myAvatar" />
                </MenuButton>
                <Menu
                    placement="bottom-end"
                    size="sm"
                    sx={{
                        zIndex: "99999",
                        p: 1,
                        gap: 1,
                        "--ListItem-radius": "var(--joy-radius-sm)",
                    }}
                >
                    {!loadingBasicAccountInfo &&
                        !errorRetrievingBasicAccountInfo && (
                            <MenuItem
                                component={Link}
                                to={UIRoutePaths.ACCOUNT}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Avatar
                                        src={DefaultAvatar}
                                        sx={{
                                            maxWidth: "32px",
                                            maxHeight: "32px",
                                        }}
                                    />
                                    <Box sx={{ ml: 1.5 }}>
                                        <Typography
                                            level="title-sm"
                                            textColor="text.primary"
                                        >
                                            {`${basicAccountInfo.firstName} ${basicAccountInfo.lastName}`}
                                        </Typography>
                                        <Typography
                                            level="body-xs"
                                            textColor="text.tertiary"
                                        >
                                            {basicAccountInfo.username}
                                        </Typography>
                                    </Box>
                                </Box>
                            </MenuItem>
                        )}
                    <ListDivider />
                    <MenuItem>
                        <HelpRoundedIcon />
                        Help
                    </MenuItem>
                    <MenuItem>
                        <SettingsRoundedIcon />
                        Settings
                    </MenuItem>
                    <ListDivider />
                    <MenuItem
                        onClick={() => {
                            beginLogout();
                        }}
                    >
                        <LogoutRoundedIcon />
                        Log out
                    </MenuItem>
                </Menu>
            </Dropdown>
            <Box>
                {submittingLogout && <Typography>logging out...</Typography>}
                {logoutSucceeds && <Typography>logout complete</Typography>}
                {logoutFails && (
                    <Typography>logout failed, please try again</Typography>
                )}
            </Box>
        </>
    );
}
