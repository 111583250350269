import * as React from "react";
import { Typography } from "@mui/joy";

export default function StatsComponent() {
    return (
        <>
            <Typography>Stats Data Here</Typography>
        </>
    );
}
