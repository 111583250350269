import { create } from "zustand";
//interface prices{[ticker: string]: Price}
//type updatingPrices = bool
//type error = bool
export const usePriceUpdateStore = create((set) => ({
    pricesByTicker: {},
    updatingPrices: false,
    errorFetchingPrices: false,
    setPricesByTicker: (prices) =>
        set((state) => {
            const newPrices = prices || {};
            const updatedPricesByTicker = {
                ...state.pricesByTicker,
                ...newPrices,
            };

            return {
                pricesByTicker: updatedPricesByTicker,
            };
        }),
    setUpdatingPrices: (updatingPrices) => set({ updatingPrices }),
    setErrorFetchingPrices: (error) => set({ error }),
    resetStore: () =>
        set({
            pricesByTicker: {},
            updatingPrices: true,
            errorFetchingPrices: false,
        }),
}));
