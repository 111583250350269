import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/joy";

export default function ThrottleClock({ throttleTimeInMS }) {
    const [time, setTime] = useState(throttleTimeInMS);
    const timeRef = useRef(null);
    const minutes = parseInt(time / 60000);
    const seconds = parseInt((time % 60000) / 1000);

    useEffect(() => {
        timeRef.current = setInterval(() => {
            setTime((prev) => Math.max(0, prev - 1000));
            if (time - 1000 <= 0) {
                clearInterval(timeRef.current);
            }
        }, 1000);

        return () => clearInterval(timeRef.current);
    }, []);

    return (
        <Typography>
            updating in {minutes} : {seconds}
        </Typography>
    );
}
