import { useEffect, useState } from "react";
//type apiCallPromise = Promise<{data, status} | {error, status}>

export const useAPI = (apiCallPromise) => {
    const [apiState, setAPIState] = useState({
        loading: true,
        status: 200,
        data: null,
        error: false,
    });

    useEffect(() => {
        apiCallPromise
            .then((res) => {
                if (res.error) {
                    setAPIState((state) => ({
                        ...state,
                        status: res.status,
                        error: true,
                    }));
                } else if (res.data) {
                    setAPIState((state) => ({
                        ...state,
                        status: res.status,
                        data: res.data,
                        error: false,
                    }));
                } 
            })
            .finally(() =>
                setAPIState((state) => ({ ...state, loading: false })),
            );
    }, []);

    return apiState;
};
