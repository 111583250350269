import { useContext } from "react";
import { PriceService } from "../../store/priceUpdates/priceUpdatesService.state";
import { Typography } from "@mui/joy";

export default function PriceComponent({ ticker }) {
    const { priceService } = useContext(PriceService);
    const priceState = priceService.subscribeToPriceUpdatesByTicker(ticker);
    //TODO: Probably can remove this component
    return (
        <>
            {/* {priceState.loadingInitial && (
                <Typography>fetching initial price</Typography>
            )}
            {!priceState.loadingInitial && (
                <Typography>
                    Current Price: {priceState.closePrice}
                    <br />
                    Previous Close Price: {priceState.previousClosePrice}
                    <br />
                    Open Price: {priceState.openPrice}
                    <br />
                    Change in Price: {priceState.priceChange}
                </Typography>
            )} */}
        </>
    );
}
