import { useContext } from "react";
import { AccountState } from "../state/account.context";
import { Box, Typography } from "@mui/joy";
import { UIRoutePaths } from "../router/fantasy-stocks-router";
import { Link } from "react-router-dom";

export default function AccountScreen() {
    const { basicAccountInfoState } = useContext(AccountState);
    const {
        loadingBasicAccountInfo,
        errorRetrievingBasicAccountInfo,
        basicAccountInfo,
    } = basicAccountInfoState;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: "1em" }}>
            <Typography>
                {loadingBasicAccountInfo
                    ? "loading"
                    : errorRetrievingBasicAccountInfo
                      ? "error"
                      : JSON.stringify(basicAccountInfo, null, 2)}
            </Typography>
            <Box>
                <Link to={UIRoutePaths.EXPERIENCE_HISTORY}>
                    View Experience Points
                </Link>
            </Box>
        </Box>
    );
}
