import { APIPaths } from "./paths";
import { CONTEXT_OPTIONS } from "./helpers/APIContext";
import { apiContext } from ".";

export const getAllUserCards = async () => {
    return await apiContext.get(APIPaths.CARDS, {
        [CONTEXT_OPTIONS.NEEDS_AUTH]: true,
    });
};

export const getUserCardDetails = async (id) => {
    return await apiContext.get(`${APIPaths.CARDS}/${id}`, {
        [CONTEXT_OPTIONS.NEEDS_AUTH]: true,
    });
};

export const getCardByTicker = async (ticker) => {
    const allCards = await getAllUserCards();
    return allCards.filter((card) => card.ticker === ticker);
};
