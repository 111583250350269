import { Typography } from "@mui/joy";
import { convertISODateStringToLocalFormat } from "../../utils/timeStampFormatTools";

//converts isodateString into a format more friendly to the user
//isodateString in YYYY-MM-DDTHH:MM:SS form

//note: only does conversion to a local format accepted by the user's location or settings for now
//also drops the HH:MM:SS part by default

//todo: obviously add extra flexibility for formatting
export default function ISODateStringFormtter({
    isoDateString,
    timelocalization,
}) {
    const formatted = convertISODateStringToLocalFormat(
        isoDateString,
        timelocalization,
    );
    return <>{formatted}</>;
}
