import { withAuthTokenHeader } from "./utils";
import { APIPaths } from "./paths";

export const getBasicAccountInfo = async () => {
    let res = {};
    let returned = {};
    try {
        returned = await withAuthTokenHeader().get(APIPaths.ACCOUNT);
        res = { status: returned.status, data: returned.data };
    } catch (e) {
        res = { status: returned.status, error: e };
    }
    return res;
};

export const getExperienceData = async () => {
    /*let res = {};
    let returned = {};
    try {
        returned = await withAuthTokenHeader().get(APIPaths.EXPERIENCE);
        res = { status: returned.status, data: returned.data };
    } catch (e) {
        res = { status: returned.status, error: e };
    }
    return res;
    */

    return {
        data: [
            { source: "SIGNUP", amount: 5, info: "New Signup" },
            {
                source: "TOURNAMENT_ENTRY",
                amount: 10,
                info: "Tournament Entry",
            },
        ],
    };
};
