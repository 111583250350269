import { useContext } from "react";
import { AccountState } from "../state/account.context";
import { Box, Grid } from "@mui/joy";
import { RootPaths, UIRoutePaths } from "../router/fantasy-stocks-router";
import { Link } from "react-router-dom";

export default function ExperienceScreen() {
    const { experienceHistoryState } = useContext(AccountState);
    const {
        experienceHistory,
        loadingExperienceHistory,
        errorRetrievingExperienceHistory,
    } = experienceHistoryState;

    return (
        <Box>
            {loadingExperienceHistory ? (
                "loading"
            ) : errorRetrievingExperienceHistory ? (
                "error"
            ) : (
                <ExperienceGrid experienceHistory={experienceHistory} />
            )}
        </Box>
    );
}

function ExperienceGrid({ experienceHistory }) {
    const col1Size = 5;
    const col2Size = 3;
    const col3Size = 4;

    return (
        <Box>
            <Grid
                container
                sx={{
                    "& .MuiBox-root": {
                        boxSizing: "border-box",
                        padding: "3px",
                        borderRight: "1px solid",
                        borderBottom: "1px solid",
                    },
                    borderTop: "1px solid",
                    borderLeft: "1px solid",
                }}
            >
                <Grid xs={col1Size}>
                    <Box>Source</Box>
                </Grid>
                <Grid xs={col2Size}>
                    <Box>Amount</Box>
                </Grid>
                <Grid xs={col3Size}>
                    <Box>Info</Box>
                </Grid>
                {experienceHistory.map((history) => (
                    <>
                        <Grid xs={col1Size}>
                            <Box>{history.source}</Box>
                        </Grid>
                        <Grid xs={col2Size}>
                            <Box>{history.amount}</Box>
                        </Grid>
                        <Grid xs={col3Size}>
                            <Box>{history.info}</Box>
                        </Grid>
                    </>
                ))}
            </Grid>
        </Box>
    );
}
