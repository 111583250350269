import { APIPaths } from "./paths";
import axios from "axios";
import { withAuthTokenHeader } from "./utils";

export const loginUser = async (username, password) => {
    //NOTE: we put more emphasis on the staus code than in other
    //api functions like fetching tournaments so the user can
    //discern a server error from a credential error
    let resp;
    try {
        const returned = await axios.post(APIPaths.LOGIN, {
            username,
            password,
        });
        resp = { token: returned.data.token, status: returned.status };
    } catch (e) {
        resp = { status: e.status };
    }

    return resp;
};

export const logoutUser = async () => {
    let resp = {};
    try {
        const returned = await withAuthTokenHeader().delete(APIPaths.LOGOUT);
        resp = { status: returned.status };
    } catch (e) {
        resp = { status: e.status };
    }

    return resp;
};
