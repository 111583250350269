import { useEffect, useState, useRef } from "react";
import { pulseCheck } from "../api/pulseCheck.service";

export const useAPIPulseCheck = () => {
    const [connectionStatus, setConnectionStatus] = useState({
        checking: true,
        passed: false,
        failed: false,
    });

    const PULSE_CHECK_FREQ = 10000;
    const timeOutBetweenChecks = useRef(null);

    useEffect(() => {
        if (connectionStatus.checking === true) {
            pulseCheck()
                .then((res) => {
                    if (res.error) {
                        setConnectionStatus({
                            ...connectionStatus,
                            checking: false,
                            failed: true,
                            passed: false,
                        });
                    } else if (res.data) {
                        setConnectionStatus({
                            ...connectionStatus,
                            checking: false,
                            failed: false,
                            passed: true,
                        });
                    }
                })
                .finally(() => {
                    timeOutBetweenChecks.current = setTimeout(() => {
                        setConnectionStatus({
                            checking: true,
                            failed: false,
                            passed: false,
                        });
                    }, PULSE_CHECK_FREQ);
                });
        }
    }, [connectionStatus]);

    useEffect(() => {
        return () => {
            clearTimeout(timeOutBetweenChecks.current);
        };
    }, []);

    return connectionStatus;
};
