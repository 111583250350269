import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import { SettingsPaths } from "../setings-router";

export default function SettingsNavigation() {
    const location = useLocation();
    const navigate = useNavigate();
    //aka the path the user can return to after exiting settings
    const exitPath = location.pathname + (location.search || "");

    const navigateTo = (e, path) => {
        e.preventDefault();
        navigate(path);
    };

    return (
        <List
            size="sm"
            sx={{
                "--ListItem-radius": "var(--joy-radius-sm)",
                "--List-gap": "4px",
            }}
        >
            <ListItem nested>
                <List
                    aria-labelledby="nav-list-browse"
                    sx={{ "& .JoyListItemButton-root": { p: "8px" } }}
                >
                    {" "}
                    <ListItem>
                        <ListItemDecorator>
                            <SettingsRoundedIcon sx={{ fontSize: 16 }} />{" "}
                        </ListItemDecorator>
                        <ListItemContent
                            sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                            }}
                        >
                            Settings
                        </ListItemContent>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            component={Link}
                            onClick={(e) =>
                                navigateTo(e, SettingsPaths.APPEARANCE_SETTINGS)
                            }
                        >
                            <ListItemDecorator>
                                <PeopleRoundedIcon fontSize="small" />
                            </ListItemDecorator>
                            <ListItemContent>Appearance</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            component={Link}
                            onClick={(e) => navigateTo(e, exitPath)}
                        >
                            <ListItemDecorator>
                                <KeyboardBackspaceIcon fontSize="small" />
                            </ListItemDecorator>
                            <ListItemContent>Exit Settings</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                </List>
            </ListItem>
        </List>
    );
}
