import { URLBuilder } from "../utils/URLBuilder";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const BASE_ROUTE_V1 = "/api/v1";

export const FULL_BASE_URL = `${BASE_URL}${BASE_ROUTE_V1}`;

export const APIPaths = {
    ACCOUNT: `${FULL_BASE_URL}/accounts/me`,
    CARDS: `${FULL_BASE_URL}/cards`,
    EXPERIENCE: `${FULL_BASE_URL}/experience`,
    LEADERBOARD: `${FULL_BASE_URL}/tournaments/:tournamentId/leaderboard`,
    LOGIN: `${FULL_BASE_URL}/auth/login`,
    LOGOUT: `${FULL_BASE_URL}/auth/logout`,
    NEWS: `${FULL_BASE_URL}/news`,
    PULSE_CHECK: `${FULL_BASE_URL}/ping`,
    RETURN: `/return`,
    RETURNS: `${FULL_BASE_URL}/returns`,
    SEARCH: `${FULL_BASE_URL}/search`,
    SETTINGS: `${FULL_BASE_URL}/settings`,
    STOCKS: `${FULL_BASE_URL}/stocks`,
    STOCKS_BY_TICKERS: `${FULL_BASE_URL}/stocks/bulk/current-market-data`,
    TOURNAMENTS: `${FULL_BASE_URL}/tournaments`,
};

export const urlBuilder = URLBuilder.create(APIPaths);
