import { useState } from "react";

export const RegistrationStatusTypes = {
    CHECKING_STATUS: "checking status",
    MUST_REGISTER: "must register",
    IS_REGISTERED: "is registered",
};

export const useRegistrationStatus = () => {
    const [userRegistrationState, setUserRegistrationState] = useState({
        checkingRegistration: true,
        mustRegister: false,
        registered: false,
        cardEntries: [],
    });

    //interface Status{ type: in RegistrationStatusTypes, cardEntries?: cards[]}
    const updateStatus = (status) => {
        switch (status.type) {
            case RegistrationStatusTypes.CHECKING_STATUS:
                setUserRegistrationState((state) => ({
                    checkingRegistration: true,
                    mustRegister: false,
                    registered: false,
                    cardEntries: [],
                }));
                break;
            case RegistrationStatusTypes.MUST_REGISTER:
                setUserRegistrationState((state) => ({
                    checkingRegistration: false,
                    mustRegister: true,
                    registered: false,
                    cardEntries: [],
                }));
                break;
            case RegistrationStatusTypes.IS_REGISTERED:
                setUserRegistrationState((state) => ({
                    checkingRegistration: false,
                    mustRegister: false,
                    registered: true,
                    cardEntries: status.cardEntries,
                }));
                break;
            default:
                throw new Error("invalid registration status passed", status);
        }
    };

    return { userRegistrationState, updateStatus };
};
