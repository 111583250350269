import { Switch, Typography } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import {
    SettingsService,
    SettingsTypes,
} from "../../../state/settings.context";

export default function AppearanceSettingsComponent() {
    return <DarkModeToggle />;
}

function DarkModeToggle() {
    const { subscribeToSetting } = useContext(SettingsService);
    const appearanceSettings = subscribeToSetting(SettingsTypes.APPEARANCE);
    const { appearanceState, AppearanceTypes, updateAppearance } =
        appearanceSettings;
    const [darkModeOn, setDarkModeOn] = useState(
        appearanceState.backgroundColor === AppearanceTypes.COLORS.BLACK,
    );

    useEffect(() => {
        const colorToChangeTo = darkModeOn
            ? AppearanceTypes.COLORS.BLACK
            : AppearanceTypes.COLORS.APPBODY;
        updateAppearance({
            [AppearanceTypes.PROPS.BACKGROUND_COLOR]: colorToChangeTo,
        });
    }, [darkModeOn]);

    return (
        <Typography
            component="label"
            onClick={() => {
                setDarkModeOn(!darkModeOn);
            }}
            endDecorator={<Switch checked={darkModeOn} sx={{ ml: 1 }} />}
        >
            {darkModeOn && "light mode"}
            {!darkModeOn && "dark mode"}
        </Typography>
    );
}
