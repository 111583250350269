import { createContext, useEffect, useState } from "react";
import { getBasicAccountInfo, getExperienceData } from "../api/account.service";

export const useBasicAccountInfo = () => {
    const [basicAccountInfo, setBasicAccountInfo] = useState(null);
    const [loadingBasicAccountInfo, setLoadingBasicAccountInfo] =
        useState(true);
    const [
        errorRetrievingBasicAccountInfo,
        setErrorRetrievingBasicAccountInfo,
    ] = useState(false);

    useEffect(() => {
        getBasicAccountInfo()
            .then((res) => {
                if (res.error) {
                    setErrorRetrievingBasicAccountInfo(true);
                } else if (res.data) {
                    setBasicAccountInfo(res.data);
                }
            })
            .finally(() => {
                setLoadingBasicAccountInfo(false);
            });
    }, []);

    return {
        basicAccountInfo,
        loadingBasicAccountInfo,
        errorRetrievingBasicAccountInfo,
    };
};

export const useExperienceHistory = () => {
    const [experienceHistory, setExperienceHistory] = useState(null);
    const [loadingExperienceHistory, setLoadingExperienceHistory] =
        useState(true);
    const [
        errorRetrievingExperienceHistory,
        setErrorRetrievingExperiencetHistory,
    ] = useState(false);

    useEffect(() => {
        getExperienceData()
            .then((res) => {
                if (res.error) {
                    setErrorRetrievingExperiencetHistory(true);
                } else if (res.data) {
                    setExperienceHistory(res.data);
                }
            })
            .finally(() => {
                setLoadingExperienceHistory(false);
            });
    }, []);

    return {
        experienceHistory,
        loadingExperienceHistory,
        errorRetrievingExperienceHistory,
    };
};

export const AccountState = createContext({
    accountState: null,
});

export const AccountProvider = ({ children }) => {
    const basicAccountInfoState = useBasicAccountInfo();
    const experienceHistoryState = useExperienceHistory();

    return (
        <AccountState.Provider
            value={{ basicAccountInfoState, experienceHistoryState }}
        >
            {children}
        </AccountState.Provider>
    );
};
