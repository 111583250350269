import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { getUserRegistrationStateForTournament } from "../../../api/tournaments.service";
import { RegistrationStatusTypes } from "../../../state/useTournamentRegistration.state";

export default function TournamentAccessComponent() {
    const { tournament, updateStatus } = useOutletContext();
    const [loadingPlayingStatus, setLoadingPlayingStatus] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        getUserRegistrationStateForTournament(tournament.id)
            .then((res) => {
                if (res.error) {
                    setError(true);
                } else {
                    const isPlaying =
                        res.data.cards && res.data.cards.length ? true : false;
                    if (isPlaying) {
                        updateStatus({
                            type: RegistrationStatusTypes.IS_REGISTERED,
                            cardEntries: res.data.cards,
                        });
                    } else {
                        updateStatus({
                            type: RegistrationStatusTypes.MUST_REGISTER,
                        });
                    }
                }
            })
            .finally(() => setLoadingPlayingStatus(false));
    }, [tournament.id]);

    return loadingPlayingStatus === true ? (
        "fetching player entry-status"
    ) : error === true ? (
        "error"
    ) : (
        <></>
    );
}
