import { useState } from "react";
import { Box, CssBaseline, CssVarsProvider } from "@mui/joy";
import Layout from "../dashboard/Layout";
import SettingsNavigation from "./components/SettingsNavigation";
import Header from "../dashboard/Header";
import HamburgerNav from "../responsive/HamburgerNav";

export default function SettingsScreen({ children }) {
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            {drawerOpen && (
                <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
                    <SettingsNavigation />
                </Layout.SideDrawer>
            )}
            <Layout.Root
                sx={[
                    drawerOpen && {
                        height: "100vh",
                        overflow: "hidden",
                    },
                ]}
            >
                <Layout.Header>
                    <Box sx={{ display: { sm: "none" } }}>
                        <HamburgerNav
                            openMenu={() => setDrawerOpen(true)}
                            closeMenu={() => setDrawerOpen(false)}
                        />
                    </Box>
                    <Header />
                </Layout.Header>
                <Layout.SideNav>
                    <SettingsNavigation />
                </Layout.SideNav>
                <Layout.Main style={{ width: "100%" }}>{children}</Layout.Main>
            </Layout.Root>
        </CssVarsProvider>
    );
}
