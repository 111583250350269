export const localizations = { US: "USA" };

//converts isodateString into a format more friendly to the user
//isodateString in YYYY-MM-DDTHH:MM:SS form

//for now, it drops the THH:MM:SS by default

//todo: MAYBE down the road add more flexibility for formatting
export const convertISODateStringToLocalFormat = (isoDateString, timelocalization) => {
    let converted = isoDateString;
    switch (timelocalization) {
        case localizations.US: {
            converted = convertToAmerican(isoDateString);
            break;
        }
    }

    return converted;
};

const convertToAmerican = (isoDateString) => {
    const date = new Date(isoDateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
};
