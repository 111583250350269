import { useEffect } from "react";
import { useLocation } from "react-router";
import { Box, IconButton } from "@mui/joy";
import HamburgerIcon from "../../images/hamburger.png";

export default function HamburgerNav({ closeMenu, openMenu }) {
    const location = useLocation();

    useEffect(() => {
        //close nav menu when we navigate after selecting a nav item
        closeMenu();
    }, [location]);

    return (
        <Box>
            <IconButton size="sm" onClick={(e) => openMenu()}>
                <img src={HamburgerIcon} height={20} />
            </IconButton>
        </Box>
    );
}
