import { Snackbar } from "@mui/joy";
import { useAPIPulseCheck } from "../state/useAPIPulseCheck.state";

export default function ConnectionStatusMessage() {
    const connectionStatus = useAPIPulseCheck();

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={connectionStatus.failed === true}
            autoHideDuration={1000}
        >
            Cannot reach API
        </Snackbar>
    );
}
