import { Typography } from "@mui/joy";
import { Link } from "react-router-dom";
import { UIRoutePaths } from "../router/fantasy-stocks-router";

export default function AwardsComponent() {
    return (
        <>
            <Typography level="h3">Awards</Typography>

            <Link to={UIRoutePaths.EXPERIENCE_HISTORY}>
                View Experience Points
            </Link>
        </>
    );
}
