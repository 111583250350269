import { withAuthTokenHeader } from "./utils";
import { APIPaths, FULL_BASE_URL } from "./paths";

export const getReturns = async (ticker) => {
    const res = {};
    try {
        const returned = await withAuthTokenHeader().get(APIPaths.RETURNS);
        res.data = returned.data;
    } catch (e) {
        res.error = e;
    }
    return res;
};

export const getReturnByTicker = async (ticker) => {
    const res = {};
    try {
        const returned = await withAuthTokenHeader().get(
            `${FULL_BASE_URL}/${ticker}${APIPaths.RETURN}`,
        );
        res.data = returned.data;
    } catch (e) {
        res.error = e;
    }
    return res;
};
