import React from "react";
import {
    Navigate,
    Outlet,
    generatePath,
    matchPath,
    useLocation,
    useMatch,
    useOutletContext,
} from "react-router-dom";
import { UIRoutePaths } from "../fantasy-stocks-router";
import { useRegistrationStatus } from "../../state/useTournamentRegistration.state";

export const TournamentRegistrationGuardComponent = () => {
    const { userRegistrationState, updateStatus } = useRegistrationStatus();
    const { tournament } = useOutletContext();
    const { cardEntries, checkingRegistration, mustRegister, registered } =
        userRegistrationState;

    const isAtVerificationRoute = useMatch(UIRoutePaths.TOURNAMENT_DETAILS)
        ? true
        : false;

    const isAtRegistrationRoute = useMatch(
        `${UIRoutePaths.TOURNAMENT_REGISTRATION}*`,
    )
        ? true
        : false;

    const isAtGameScreen = useMatch(`${UIRoutePaths.TOURNAMENT_GAME_SCREEN}*`)
        ? true
        : false;

    const mustNavToVerificationRoute =
        checkingRegistration && !isAtVerificationRoute;

    const mustNavToRegistrationRoute = mustRegister && !isAtRegistrationRoute;

    const mustNavToGameScreen = registered && !isAtGameScreen;

    let pathToRedirectTo = null;

    if (mustNavToVerificationRoute === true) {
        pathToRedirectTo = generatePath(UIRoutePaths.TOURNAMENT_DETAILS, {
            id: tournament.id,
        });
    } else if (mustNavToGameScreen === true) {
        pathToRedirectTo = generatePath(UIRoutePaths.TOURNAMENT_GAME_SCREEN, {
            id: tournament.id,
        });
    } else if (mustNavToRegistrationRoute === true) {
        pathToRedirectTo = generatePath(UIRoutePaths.TOURNAMENT_REGISTRATION, {
            id: tournament.id,
            numCardsNeeded: tournament.numberOfCards,
        });
    }

    return pathToRedirectTo ? (
        <Navigate to={pathToRedirectTo} replace={true} />
    ) : (
        <Outlet context={{ tournament, cardEntries, updateStatus }} />
    );
};
